import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

const apiKey = 'AIzaSyD1tGXuLnXhYVDXL7qabMygTzt5_QlPhkI';

var styles = [
  {
    featureType: 'all',
    stylers: [
      { hue: '#0033cc' },
      { saturation: -70 },
      { lightness: 10 }
    ]
  }, {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }
    ],
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }
]

export class MapContainer extends React.Component {
  render() {
    const { google } = this.props;
    const lat = '51.513279568020906';
    const lng = '-0.08635176722797921';

    return (
      <Map
        google={this.props.google}
        zoom={17}
        // style={style}
        styles={styles}
        initialCenter={{
          lat: lat,
          lng: lng
        }}
      >

        <Marker
          name={'Helios Underwriting PLC'}
          position={{ lat: lat, lng: lng }}
          animation={google.maps.Animation.DROP}
          icon={{
            url: '/assets/pin@2x.png',
            scaledSize: new google.maps.Size(41, 55)
          }}
        />

      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiKey
})(MapContainer)